<template>
  <v-card
    class="mx-n3 mx-sm-16 px-0 px-sm-4 px-md-8 flex-fill"
    max-width="420"
    flat
    tile
    outlined
  >
    <v-card-title class="my-2 my-sm-4 my-md-8 text-h4 font-weight-bold justify-center">
      <span v-if="response !== null">
        {{ gc_langText.inquiry.title.response[gc_lang] }}
      </span>

      <span v-else-if="mode === 'id'">
        {{ gc_langText.inquiry.title.id[gc_lang] }}
      </span>

      <span v-else>
        {{ gc_langText.inquiry.title.pw[gc_lang] }}
      </span>
    </v-card-title>

    <!--  이메일 찾기 결과  -->
    <v-card-text
      class="text-center pb-8"
      v-if="response !== null"
    >
      <ul
        v-if="response.length"
        class="c-none-list"
      >
        <li
          v-for="(item, i) in response"
          :key="`my-email-${i}`"
        >
          {{ item }}
        </li>
      </ul>

      <span v-else>
        {{ gc_langText.inquiry.responseEmpty[gc_lang] }}
      </span>
    </v-card-text>

    <!--  form  -->
    <v-card-text
      v-else
      class="py-0"
    >
      <!--  form 박스  -->
      <v-form
        ref="form"
        v-model="formValid"
      >
        <!--  이름  -->
        <v-text-field
          class="rounded-0"
          v-model="form.name"
          :label="gc_langText.common.account.name[gc_lang]"
          :rules="[gc_rules.required]"
          :disabled="requesting"
          validate-on-blur
          outlined
          dense
        />

        <!--  휴대폰 번호  -->
        <v-text-field
          v-if="mode === 'id'"
          class="rounded-0"
          v-model="form.phone"
          @input="gm_form_onlyNumber"
          @keypress.enter="gm_submitEnter"
          :rules="[gc_rules.required, gc_rules.phone]"
          :label="gc_langText.common.account.cellPhoneNumber[gc_lang]"
          :placeholder="gc_langText.common.account.withOutText[gc_lang]"
          :disabled="requesting"
          validate-on-blur
          outlined
          dense
        />

        <!--  이메일 입력  -->
        <v-row
          v-else
          class="mb-4"
        >
          <!--  이메일 프론트  -->
          <v-col
            class="pr-0"
            cols="6"
          >
            <v-text-field
              class="rounded-0"
              v-model="form.mailFront"
              @keypress.enter="gm_submitEnter"
              :label="gc_langText.common.account.mail[gc_lang]"
              :rules="[gc_rules.required, gc_rules.mailFront]"
              :disabled="requesting"
              validate-on-blur
              outlined
              dense
            >
              <template v-slot:append-outer>
                <v-icon>mdi-at</v-icon>
              </template>
            </v-text-field>
          </v-col>

          <!--  이메일 백  -->
          <v-col cols="6">
            <!--  입력란  -->
            <v-text-field
              ref="mailBack"
              class="rounded-0"
              v-model="form.mailBack"
              @keypress.enter="gm_submitEnter"
              :rules="[gc_rules.required, gc_rules.mailBack]"
              :placeholder="gc_langText.common.account.directInput[gc_lang]"
              :disabled="requesting || !!selectedMail"
              validate-on-blur
              outlined
              dense
            />

            <!--  선택란  -->
            <v-select
              class="rounded-0"
              v-model="selectedMail"
              @change="gm_form_selectMail"
              :items="gc_form_emailList"
              :disabled="requesting"
              hide-details
              outlined
              dense
            />

          </v-col>

        </v-row>

      </v-form>
    </v-card-text>

    <!--  컨트롤러  -->
    <v-card-actions class="px-4 pt-0 flex-column">

      <!--  버튼: 로그인 하기  -->
      <v-btn
        v-if="response !== null"
        class="text-h6"
        elevation="0"
        color="cyan"
        to="/login"
        block
        dark
        large
        tile
      >
        {{ gc_langText.inquiry.btn.goToLogin[gc_lang] }}
      </v-btn>

      <!--  버튼: 찾기  -->
      <v-btn
        v-else
        ref="submit"
        class="text-h6"
        @click="submit"
        :dark="!requesting"
        :disabled="requesting"
        :loading="requesting"
        elevation="0"
        color="cyan"
        block
        large
        tile
      >
        {{ gc_langText.inquiry.btn.find[gc_lang] }}
      </v-btn>

      <!--  버튼: 홈으로 이동  -->
      <v-btn
        class="my-6 ml-0 text-h6"
        elevation="0"
        to="/"
        outlined
        block
        large
        tile
      >
        {{ gc_langText.common.etc.goToHomeBtn[gc_lang] }}
      </v-btn>

      <!--  버튼: 다시찾기  -->
      <v-btn
        v-if="mode === 'id' && response !== null && !response.length"
        @click="response = null"
        class="mb-6 ml-0 text-h6"
        elevation="0"
        outlined
        block
        large
        tile
      >
        {{ gc_langText.inquiry.btn.back[gc_lang] }}
      </v-btn>

      <!--  버튼: 회원가입  -->
      <v-btn
        v-if="mode === 'id' && response !== null && !response.length"
        class="mb-6 ml-0"
        to="/join"
        plain
      >
        {{ gc_langText.inquiry.btn.create[gc_lang] }}
      </v-btn>

    </v-card-actions>

  </v-card>
</template>

<script>
export default {
  name: "Inquiry",

  data: () => ({
    // 메일 도메인 선택 값
    selectedMail: null,

    // 폼 입력 데이터
    form: {
      name: '',
      phone: '',
      mailFront: '',
      mailBack: ''
    },

    // 폼 내부 입력 값들 유효 상태
    formValid: true,

    // 요청 상태
    requesting: false,

    // 이메일 찾기 결과 상태
    //   null: 요청한적 없음
    //   []: 잘못된 정보 입력에 의한 결과
    //   ['email', ...]: 찾은 정보
    response: null
  }),

  computed: {
    mode() {
      return this.$route.params.mode
    },
  },

  methods: {
    // 폼 입력값 전송
    submit() {
      this.$refs.form.validate()

      if (this.formValid) {

        this.requesting = true

        if (this.mode === 'id') {
          this.api_findEmail()
        } else if (this.mode === 'pw') {
          this.api_findPassword()
        }

      }
    },
  }
}
</script>

<style lang="sass" scoped>
.c-none-list
  list-style: none
  padding-left: 0
</style>